import { createI18n } from 'vue-i18n'
import messages from '../Generated/translations.json'

export default createI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  silentTranslationWarn: false,
  messages,
  legacy: false,
  globalInjection: true,
  fallbackWarn: false,
  missingWarn: false,
  warnHtmlMessage: false,
})
