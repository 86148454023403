// Not sure to know why but seems to be needed
// Dig in it later
function urlBase64ToUint8Array(base64String) {
  var padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  var base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

  var rawData = window.atob(base64)
  var outputArray = new Uint8Array(rawData.length)

  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export default () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/webpush-sw.js')
      .then(registration => {
        navigator.serviceWorker.ready
          .then(registration => {
            registration.update()

            const subscribeOptions = {
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(
                import.meta.env.VITE_VAPID_PUBLIC_KEY,
              ),
            }

            return registration.pushManager.subscribe(subscribeOptions)
          })
          .then(sub => {
            const key = sub.getKey('p256dh')
            const token = sub.getKey('auth')
            const contentEncoding = (PushManager.supportedContentEncodings || [
              'aesgcm',
            ])[0]

            const data = {
              endpoint: sub.endpoint,
              public_key: key
                ? btoa(String.fromCharCode.apply(null, new Uint8Array(key)))
                : null,
              auth_token: token
                ? btoa(String.fromCharCode.apply(null, new Uint8Array(token)))
                : null,
              encoding: contentEncoding,
            }
            axios.post(route('push.subscribe'), data)
          })
      })
      .catch(error => {
        console.error('Service Worker registration failed:', error)
      })
  }
}
