import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faCubes,
  faTachometer,
  faIndustry,
  faPollH,
  faTruckMoving,
  faCopy,
  faCheck,
  faPen,
  faUndo,
  faExternalLinkAlt,
  faFileDownload,
  faExclamationCircle,
  faTrash,
  faRedo,
  faEye,
  faEyeSlash,
  faThumbsUp,
  faChartPie,
  faSortAlt,
  faSunglasses,
  faHeartbeat,
  faHeartBroken,
  faPauseCircle,
  faPlayCircle,
  faSquare,
  faShapes,
  faServer,
  faUsers,
  faCog,
  faLock,
  faBoxes,
  faDatabase,
  faLayerGroup,
  faEuroSign,
  faLeaf,
} from '@fortawesome/pro-regular-svg-icons'
import {
  faGoogle,
  faAws,
  faDigitalOcean,
} from '@fortawesome/free-brands-svg-icons'
import {
  faCopy as faCopySolid,
  faInfoCircle as faInfoCircleSolid,
  faSpinnerThird as faSpinnerThird,
  faTachometerAlt,
  faTruck,
  faSquarePollHorizontal,
  faServer as SolidServer,
  faUser,
  faCalendar,
  faQuestion,
  faGlobe,
  faEthernet,
  faCube,
  faChevronDown,
  faExternalLinkAlt as solidExternalLinkAlt,
  faTrash as solidTrash,
  faChevronLeft,
  faFileContract,
  faUnlock,
  faDownload,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faTimesCircle,
  faCheck as faCheckSolid,
  faCalendarPlus,
  faCalendarPen,
  faUserPlus,
  faUserPen,
  faRedo as SolidRedo,
  faLaptopSlash,
  faBowlingPins,
  faEllipsisVertical,
  faRocket,
  faHistory,
  faArrowDownToLine,
  faTimeline,
    faExclamation,
    faBoxOpen,
    faBoxes as SolidBoxes
} from '@fortawesome/pro-solid-svg-icons'

library.add(
  faCubes,
  faTachometer,
  faIndustry,
  faPollH,
  faTruckMoving,
  faCopy,
  faCheck,
  faPen,
  faCopySolid,
  faHistory,
  faUndo,
  faExternalLinkAlt,
  faFileDownload,
  faExclamationCircle,
  faTrash,
  faRedo,
  faEye,
  faEyeSlash,
  faGoogle,
  faThumbsUp,
  faChartPie,
  faSortAlt,
  faSunglasses,
  faHeartbeat,
  faHeartBroken,
  faPlayCircle,
  faPauseCircle,
  faSquare,
  faShapes,
  faServer,
  faInfoCircleSolid,
  faUsers,
  faAws,
  faSpinnerThird,
  faCog,
  faLock,
  faBoxes,
  faDatabase,
  faLayerGroup,
  faEuroSign,
  faDigitalOcean,
  faLeaf,
  faTachometerAlt,
  faTruck,
  faSquarePollHorizontal,
  SolidServer,
  faUser,
  faCalendar,
  faQuestion,
  faGlobe,
  faEthernet,
  faCube,
  faChevronDown,
  solidExternalLinkAlt,
  solidTrash,
  faChevronLeft,
  faUnlock,
  faFileContract,
  faDownload,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faTimesCircle,
  faCheckSolid,
  faCalendarPlus,
  faCalendarPen,
  faUserPlus,
  faUserPen,
  SolidRedo,
  faLaptopSlash,
  faBowlingPins,
  faEllipsisVertical,
  faRocket,
  faArrowDownToLine,
  faTimeline,
    faExclamation,
    faBoxOpen,
    SolidBoxes
)
