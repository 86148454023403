import { createApp, h } from 'vue'
import { createInertiaApp, Link } from '@inertiajs/vue3'
import i18n from './Plugins/i18n'
import ui from '@deliver-up/ui'
import './Plugins'
import resolvePage from 'access/shared/resolvePage'
import '../css/app.css'
import useWebPushSubscription from './Composables/Notifications/useWebPushSubscription.js'

useWebPushSubscription()

createInertiaApp({
  progress: { color: 'var(--primary-500)' },
  resolve: resolvePage,
  setup({ el, App, props, plugin }) {
    return createApp({ render: () => h(App, props) })
      .component('Link', Link)
      .use(plugin)
      .use(ui)
      .use(i18n)
      .mixin({ methods: { route } })
      .mount(el)
  },
})
